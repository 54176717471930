<template>
    <div class="profile">
        <h1>This is an profile page</h1>
    </div>
</template>


<script>
    export default {
        name: "Profile"
    }
</script>

<style scoped>

</style>