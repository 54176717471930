<template>
    <div class="create-graph">
        <b-row>
            <b-col lg="12">
                <h1>Создать график</h1>
            </b-col>
            <b-col lg="8" offset-lg="2" class="text-left">
                <b-form @submit.prevent="onSubmit" v-if="show">

                    <b-form-group
                            label="Заголовок:"
                            label-for="graph_title"
                    >
                        <b-form-input id="graph_title" v-model="form.title" placeholder="Введите заголовок графика"></b-form-input>
                    </b-form-group>

                    <b-form-group
                            label="Название шкалы:"
                            label-for="graph_yLabel"
                    >
                        <b-form-input id="graph_yLabel" v-model="form.yLabel" placeholder="Введите название вертикльной шкалы в графике"></b-form-input>
                    </b-form-group>

                    <b-form-group
                            label="Название данных:"
                            label-for="graph_xLabel"
                    >
                        <b-form-input id="graph_xLabel" v-model="form.xLabel" placeholder="Введите название горизонтальной шкалы в графике"></b-form-input>
                    </b-form-group>

                    <b-form-group
                            label="Ширина графика:"
                            label-for="graph_cols"
                    >
                        <b-form-input id="graph_cols" type="number" min="1" max="12" v-model="form.cols" placeholder="Введите ширину графика на странице"></b-form-input>
                    </b-form-group>

                    <b-form-group
                            label="Заголовок подсказки:"
                            label-for="graph_title"
                    >
                        <b-form-input id="graph_title" v-model="form.iTitle" placeholder="Введите заголовок для подсказки"></b-form-input>
                    </b-form-group>

                    <b-form-group
                            label="Подсказка:"
                            label-for="graph_title"
                    >
                        <b-form-textarea
                                id="graph_title"
                                placeholder="Подсказка"
                                rows="6"
                                v-model="form.iBody"
                        ></b-form-textarea>
                    </b-form-group>


                    <b-button v-if="form_valid" type="submit" variant="primary">Добавить</b-button>
                    <b-alert v-else show variant="warning" class="text-center">
                        Ввудите данные для добавления графика
                    </b-alert>
                </b-form>
                <div v-else>

                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "CreateGraph",
        data(){return{
            show: true,
            form:{
                user_id: null,
                title: null,
                yLabel: null,
                xLabel: null,
                cols: 4,
                iTitle: null,
                iBody: null,
            }
        }},
        computed:{
            user(){
                return this.$store.getters.getUser;
            },
            form_valid(){
                let errs = 0;
                //if(this.form.user_id == null)
                    //errs++;
                if(this.form.title == null)
                    errs++;
                if(this.form.yLabel == null)
                    errs++;
                if(this.form.xLabel == null)
                    errs++;
                if(this.form.cols < 1 || this.form.cols > 12)
                    errs++;
                if(this.form.iTitle == null)
                    errs++;
                if(this.form.iBody == null)
                    errs++;

                if(errs > 0)
                    return false;
                return  true;
            }
        },
        methods:{
            makeToast(message,variant = null,  title = 'Уведомление'){
                this.$bvToast.toast(message, {
                    title: title,
                    variant: variant,
                    solid: true
                });
            },
            onSubmit(){
                this.show= false;
                this.form.user_id = this.user.ID;
                this.$store.dispatch('createChart',this.form).then(status => {
                    if(status){
                        this.makeToast('Успешно создано');
                        this.$router.push('/');
                    }else{
                        this.makeToast('Не удалось создать', 'danger');
                        this.$router.push('/');
                    }
                });
                console.log(this.form);
            }
        },
    }
</script>

<style scoped>

</style>